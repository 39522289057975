import { Heading1, Heading3 } from '@dimatech/shared/lib/components/typography';
import { Language } from '@dimatech/shared/lib/models';
import img_icon_decision from 'assets/images/icon-decision.png';
import img_icon_evidence from 'assets/images/icon-evidence.png';
import img_icon_innovation from 'assets/images/icon-innovation.png';
import img_start_dikios_en from 'assets/images/start-dikios-en.png';
import img_start_dikios_sv from 'assets/images/start-dikios-sv.png';
import img_start_dimios_en from 'assets/images/start-dimios-en.png';
import img_start_dimios_overview_en from 'assets/images/start-dimios-overview-en.png';
import img_start_dimios_overview_sv from 'assets/images/start-dimios-overview-sv.png';
import img_start_dimios_sv from 'assets/images/start-dimios-sv.png';
import img_start_dios_en from 'assets/images/start-dios-en.png';
import img_start_dios_sv from 'assets/images/start-dios-sv.png';
import img_start_group_en from 'assets/images/start-group-en.png';
import img_start_group_sv from 'assets/images/start-group-sv.png';
import img_start_pios_en from 'assets/images/start-pios-en.png';
import img_start_pios_sv from 'assets/images/start-pios-sv.png';
import { Button } from 'components/Button';
import {
  Container,
  ContainerWrapper,
  ContainerWrapperGrid,
  ContainerWrapperHighlight,
} from 'components/Container';
import { Divider } from 'components/Divider';
import { Hero } from 'components/Hero';
import { InfoBox } from 'components/InfoBox';
import { ProductBox } from 'components/ProductBox';
import { Section } from 'components/Section';
import { View } from 'components/View';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import img_hero from 'assets/images/start-hero.jpg';

export const Home = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <Hero text={t('Home.Hero.Text')} image={img_hero} />

      <Section>
        <Container>
          <div>
            <Heading1>{t('Home.DigitalMaturity.Title')}</Heading1>

            <p>{t('Home.DigitalMaturity.Text')}</p>
            <p>{t('Home.DigitalMaturity.Text2')}</p>

            <Button
              text={t('Home.BookDemoButton.Text')}
              handleClick={() =>
                (window.location.href = `mailto:sales@dimatech.se?subject=${t(
                  'RegisterButton.MailSubject',
                  { product: 'Dimios' }
                )}`)
              }
            />
          </div>

          <div className="image hide-xs">
            <img
              src={
                i18next.language === Language.Swedish
                  ? img_start_dimios_overview_sv
                  : img_start_dimios_overview_en
              }
              alt="Översikt Dimios"
            />
          </div>
        </Container>
      </Section>

      <Container>
        <img
          className="show-xs"
          src={
            i18next.language === Language.Swedish
              ? img_start_group_sv
              : img_start_group_en
          }
          alt="Group"
          style={{ width: '100%', marginTop: 40 }}
        />
      </Container>

      <ContainerWrapperGrid>
        <Heading3>{t('Home.H2.EvidensBased')}</Heading3>

        <Container>
          <div>
            <InfoBox
              image={img_icon_evidence}
              title={t('Home.InfoBox.EvidensBased.Title')}
            >
              <p>{t('Home.InfoBox.EvidensBased.Text')}</p>
            </InfoBox>

            <InfoBox
              image={img_icon_decision}
              title={t('Home.InfoBox.DecisionBasis.Title')}
            >
              <p>{t('Home.InfoBox.DecisionBasis.Text')}</p>
            </InfoBox>

            <InfoBox
              image={img_icon_innovation}
              title={t('Home.InfoBox.Innovation.Title')}
            >
              <p>{t('Home.InfoBox.Innovation.Text')}</p>
            </InfoBox>
          </div>
        </Container>
      </ContainerWrapperGrid>

      <ContainerWrapper>
        <Container>
          <Divider />
        </Container>
      </ContainerWrapper>

      <ContainerWrapperGrid>
        <Heading3>{t('Home.H2.OurProducts')}</Heading3>

        <Container>
          <ProductBox
            image={
              i18next.language === Language.Swedish
                ? img_start_dimios_sv
                : img_start_dimios_en
            }
            title={t('Home.OurProducts.Dimios.Title')}
            linkTo="dimios"
            linkText={t('Home.OurProducts.Dimios.Link')}
            altText="Översikt Dimios"
          >
            <p>{t('Home.OurProducts.Dimios.Text')}</p>
          </ProductBox>

          <ProductBox
            image={
              i18next.language === Language.Swedish
                ? img_start_dios_sv
                : img_start_dios_en
            }
            title={t('Home.OurProducts.Dios.Title')}
            linkTo="dios"
            linkText={t('Home.OurProducts.Dios.Link')}
            altText="Översikt Dios"
          >
            <p>{t('Home.OurProducts.Dios.Text')}</p>
          </ProductBox>
        </Container>

        <Container>
          <ProductBox
            image={
              i18next.language === Language.Swedish
                ? img_start_pios_sv
                : img_start_pios_en
            }
            title={t('Home.OurProducts.Pios.Title')}
            linkTo="pios"
            linkText={t('Home.OurProducts.Pios.Link')}
            altText="Översikt Pios"
          >
            <p>{t('Home.OurProducts.Pios.Text')}</p>
          </ProductBox>

          <ProductBox
            image={
              i18next.language === Language.Swedish
                ? img_start_dikios_sv
                : img_start_dikios_en
            }
            title={t('Home.OurProducts.Dikios.Title')}
            linkTo="dikios"
            linkText={t('Home.OurProducts.Dikios.Link')}
            altText="Översikt Dikios"
          >
            <p>{t('Home.OurProducts.Dikios.Text')}</p>
          </ProductBox>
        </Container>
      </ContainerWrapperGrid>

      <ContainerWrapperHighlight>
        <Container>
          <div>
            <Heading3>{t('Home.About.Title')}</Heading3>
            <p>{t('Home.About.Text')}</p>
          </div>
        </Container>
      </ContainerWrapperHighlight>
    </View>
  );
};

Home.displayName = 'Home';
